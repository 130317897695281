import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Checkbox, Modal } from 'antd';

import JobCountry from 'components/Job/JobCountry';

import { useMobileDesign } from 'hooks/useMobileDesign';
import useSortedArrayByProp from 'hooks/useSortedArrayByProp';

import { fbAnalytics } from 'utils/analytics';

import { GTM_EVENTS } from 'constants/analytics';

import { ReactComponent as ClearIcon } from 'assets/icons/clear-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import styles from './FilterJobs.module.scss';

interface JobData {
  id: string;
  fields: {
    'Location (Country)': string;
    Industry?: string[];
  };
}

interface FilterJobsProps {
  updateFilters: (filters: {
    countryFilter: string;
    departmentFilter: string;
    industryFilter: string;
  }) => void;
  country?: string;
  industry?: string;
  department?: string;
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
  withDataQa?: boolean;
  jobsData: JobData[];
}

const FilterJobs: FC<FilterJobsProps> = ({
  updateFilters,
  country = '',
  industry = '',
  department = '',
  isModalVisible,
  setIsModalVisible,
  withDataQa = false,
  jobsData
}) => {
  const [checkedCountries, setCheckedCountries] = useState<string[]>(
    country?.split(',') || []
  );
  const [checkedIndustries, setCheckedIndustries] = useState<string[]>(
    industry?.split(',') || []
  );
  const [checkedDepartments, setCheckedDepartments] = useState<string[]>(
    department?.split(',') || []
  );
  const [industries, setIndustries] = useState<string[]>([]);
  const [countries, setCountryCodes] = useState<
    { key: string; value: string }[]
  >([]);
  const isMobile = useMobileDesign(500);

  useEffect(() => {
    const seenCountries = new Set<string>();

    const extractedCountries = jobsData
      .map((record) => ({
        key: record.id,
        value: record.fields['Location (Country)']
      }))
      .sort((a, b) => a?.value?.[0]?.localeCompare(b?.value?.[0]))
      .filter((country) => {
        if (seenCountries.has(country.value)) {
          return false;
        }
        seenCountries.add(country.value);
        return true;
      });

    const extractedIndustries = new Set<string>();
    jobsData.forEach((record) => {
      if (record.fields.Industry) {
        record.fields.Industry.forEach((industry) => {
          extractedIndustries.add(industry);
        });
      }
    });

    const sortedIndustries = Array.from(extractedIndustries).sort((a, b) =>
      a.localeCompare(b)
    );
    setCountryCodes(extractedCountries);
    setIndustries(Array.from(sortedIndustries));
  }, [jobsData]);

  const sortedCountries = useSortedArrayByProp(countries, 'value');

  const closeModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onClearFiltersModal = () => {
    setCheckedCountries([]);
    setCheckedDepartments([]);
    setCheckedIndustries([]);
    updateFilters({
      countryFilter: '',
      departmentFilter: '',
      industryFilter: ''
    });
  };

  const onClearFilters = () => {
    fbAnalytics(GTM_EVENTS.CLEAR_JOBS);
    updateFilters({
      countryFilter: '',
      departmentFilter: '',
      industryFilter: ''
    });
    onClearFiltersModal();
  };

  const onApplyFilters = () => {
    fbAnalytics(GTM_EVENTS.FILTER_JOBS);
    updateFilters({
      countryFilter: checkedCountries.toString(),
      departmentFilter: checkedDepartments.toString(),
      industryFilter: checkedIndustries.toString()
    });
    closeModal();
  };

  useEffect(() => {
    if (country) {
      setCheckedCountries(country.split(','));
    }
    if (industry) {
      setCheckedIndustries(industry.split(','));
    }
  }, [country, industry]);

  const getCountryOptions = (countries: { key: string; value: string }[]) =>
    countries.map(({ key, value }) => ({
      label: <JobCountry countryName={value} countryCode={key} />,
      value: value || ''
    }));

  const getIndustryOptions = (industries: string[]) =>
    industries.map((value) => ({
      label: value,
      value
    }));

  return (
    <div
      className={styles.FilterJobs}
      style={{ width: isMobile ? '100%' : 'auto' }}
    >
      <div className={styles.FilterJobsButtons}>
        <Button
          type="default"
          data-qa={withDataQa ? 'filterJobsButton' : ''}
          className={
            country ? `${styles.FilterButton} btn filter-btn` : 'btn filter-btn'
          }
          onClick={() => setIsModalVisible(true)}
          style={{ width: isMobile ? '100%' : 'auto' }}
        >
          <FilterIcon />
          <FormattedMessage id="filter.jobs" />
        </Button>
        {(country || industry) && (
          <Button
            type="default"
            className={`${styles.ClearButton} btn filter-btn`}
            onClick={onClearFilters}
            style={{
              marginTop: isMobile ? '8px' : '0',
              marginLeft: isMobile ? '0' : '20px',
              width: isMobile ? '100%' : 'auto'
            }}
          >
            <ClearIcon width="14px" height="14px" />
            <FormattedMessage id="button.clear_filters" />
          </Button>
        )}
      </div>
      {!country && (
        <p>
          <FormattedMessage id="filter_jobs.text" />
        </p>
      )}
      <div className={styles.selectedWrap}>
        {country && (
          <div className={styles.SelectedFilters}>
            <span className={styles.CategoryTitle}>
              <FormattedMessage id="filter.country" />:
            </span>
            <div className={styles.CategoryList}>
              {checkedCountries.map((c) => (
                <JobCountry key={c} countryName={c} countryCode={c} />
              ))}
            </div>
          </div>
        )}
        {industry && (
          <div className={styles.SelectedFilters}>
            <span className={styles.CategoryTitle}>
              <FormattedMessage id="filter.industry" />:
            </span>
            <div className={styles.CategoryList}>
              {checkedIndustries.map((i) => (
                <span key={i}>{i}</span>
              ))}
            </div>
          </div>
        )}
      </div>
      <Modal
        open={isModalVisible}
        onCancel={closeModal}
        title={<FormattedMessage id="filter.jobs" />}
        className="modal filter-modal"
        footer={[
          <Button
            key="btn-filter-apply"
            type="primary"
            className="btn btn-primary"
            onClick={onApplyFilters}
          >
            <FormattedMessage id="button.apply_filters" />
          </Button>,
          <Button
            key="btn-filter-clear"
            type="link"
            className="link-btn icon-link"
            onClick={onClearFiltersModal}
          >
            <ClearIcon />
            <FormattedMessage id="button.clear_filters" />
          </Button>
        ]}
      >
        <div className="block-scroll">
          <h3>
            <FormattedMessage id="filter.country" />:
          </h3>
          <Checkbox.Group
            className={styles.FilterList}
            options={getCountryOptions(sortedCountries)}
            value={checkedCountries}
            onChange={(checkedValues) =>
              setCheckedCountries(checkedValues as string[])
            }
          />
          <h3>
            <FormattedMessage id="filter.industry" />:
          </h3>
          <Checkbox.Group
            className={styles.FilterList}
            options={getIndustryOptions(industries)}
            value={checkedIndustries}
            onChange={(checkedValues) =>
              setCheckedIndustries(checkedValues as string[])
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default FilterJobs;
