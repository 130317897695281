import { FC, ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Select } from 'antd';
import { FormInstance } from 'antd/es/form';

interface SelectCustomProps {
  name: string;
  form: FormInstance;
  options: { value: string; label: string; code?: string }[];
  placeholderId?: string;
  labelId: string;
  optionRender?: (data: any) => ReactElement;
  labelRender?: (data: any) => ReactElement;
  loading?: boolean;
  disabled?: boolean;
  showSearch?: boolean;
  optionFilterProp?: string;
}

const SelectCustom: FC<SelectCustomProps> = ({
  name,
  form,
  options,
  placeholderId,
  labelId,
  optionRender,
  labelRender,
  disabled = false,
  ...otherProps
}) => {
  const intl = useIntl();
  const [currentValue, setCurrentValue] = useState<any>(
    form.getFieldValue(name)
  );

  useEffect(() => {
    const fieldValue = form.getFieldValue(name);
    if (fieldValue !== currentValue) {
      setCurrentValue(fieldValue);
    }
  }, [form, name, currentValue]);

  const handleChange = (value: any): void => {
    form.setFieldsValue({ [name]: value });
    setCurrentValue(value);
  };

  return (
    <Select
      options={options}
      value={currentValue}
      onChange={handleChange}
      optionRender={optionRender}
      labelRender={labelRender}
      disabled={disabled}
      placeholder={
        placeholderId ? intl.formatMessage({ id: placeholderId }) : ''
      }
      {...otherProps}
    />
  );
};

export default SelectCustom;
