const MAIN = '/';
const JOBS = '/jobs';
const JOB_DETAILS = '/jobs/:id';
const APPLY = '/apply';
const USER = '/users';
const APPLY_ENROLL = '/enroll'; // TODO: remove?
const APPLY_INVITE = '/invite/:id';
const APPLY_ENROLL_URK = '/enroll-ukr-refugee';
const APPLY_ENROLL_CANADA_LABOUR = '/applyCanadaLabour';
const APPLY_ENROLL_CANADA_CLERK = '/applyCanadaClerk';
const APPLY_ENROLL_CANADA_DRIVER = '/applyCanadaDriver';
const APPLY_ENROLL_CANADA_PHARMACY_ASSISTANT = '/applyCanadaPharmacyAssistant';
const APPLY_ENROLL_CANADA_BUTCHER = '/applyCanadaButcher';
const APPLY_SUCCESS = '/apply/success';
const NOT_FOUND = '/not-found';
const EMPLOYER = '/employer';
const CONGRATULATIONS = '/congratulations';
const INTAKE = '/intake';
const INTAKE_THANK_YOU = '/intake/thank-you';
const ADAMO = '/adamo';
const AMBASSADOR = '/ambassador';
const AMBASSADOR_JOBS = '/jobs/ambassador';
const AMBASSADOR_INTAKE = '/intake/ambassador/:id';
const AMBASSADOR_DETAILS = '/jobs/ambassador/:id';
const AMBASSADOR_THANK_YOU = '/intake/ambassador/thank-you';
const ADMIN = '/admin';
const CHAT = '/admin/chat';
const QUESTIONNAIRE = '/questionnaire/:jobId/:userId/:applicationId';
const QUESTIONNAIRE_THANK_YOU = '/questionnaire/thank-you';
const USER_PROFILE = '/users/me';

export {
  MAIN,
  JOBS,
  JOB_DETAILS,
  APPLY,
  USER,
  APPLY_ENROLL,
  APPLY_INVITE,
  APPLY_ENROLL_URK,
  APPLY_ENROLL_CANADA_LABOUR,
  APPLY_ENROLL_CANADA_CLERK,
  APPLY_ENROLL_CANADA_DRIVER,
  APPLY_ENROLL_CANADA_PHARMACY_ASSISTANT,
  APPLY_ENROLL_CANADA_BUTCHER,
  APPLY_SUCCESS,
  NOT_FOUND,
  EMPLOYER,
  CONGRATULATIONS,
  INTAKE,
  INTAKE_THANK_YOU,
  CHAT,
  ADAMO,
  AMBASSADOR,
  AMBASSADOR_JOBS,
  AMBASSADOR_INTAKE,
  AMBASSADOR_THANK_YOU,
  AMBASSADOR_DETAILS,
  ADMIN,
  QUESTIONNAIRE,
  QUESTIONNAIRE_THANK_YOU,
  USER_PROFILE
};
