import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import { getOptionsGroupedByParentKey } from 'utils/getOptionsGroupedByParentKey';

import { FormattedOption } from 'types/dictionaries';

import { Dictionaries } from 'constants/enums';

import SelectCommon from './SelectCommon';

interface SkillsetSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  title?: string;
}

const SkillsetSelect: FC<SkillsetSelectProps> = ({
  value,
  onChange,
  title = 'Skillset',
  ...restProps
}) => {
  const skillsetDict = useSelector(
    selectDictionary(Dictionaries.Skillset)
  ) as FormattedOption[];

  const skillsetOptions = useMemo(
    () =>
      getOptionsGroupedByParentKey({
        itemsList: skillsetDict,
        labelKey: ['label'],
        valueKey: ['value'],
        parentKey: ['parentKey']
      }),
    [skillsetDict]
  );

  const handleChange: (value: string[]) => void = (value) => {
    onChange?.(value);
  };

  return (
    <SelectCommon
      title={title}
      value={value}
      onChange={handleChange}
      customOptions={skillsetOptions}
      placeholder="Please select skillset"
      {...restProps}
    />
  );
};

export default SkillsetSelect;
