import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  SortAscendingOutlined,
  SortDescendingOutlined
} from '@ant-design/icons';
import { Button, Row } from 'antd';
import AirtableService from 'services/airtable';

import EmptyList from 'components/EmptyList';
import JobsList from 'components/Job/JobsList';
import LoadingIndicator from 'components/LoadingIndicator';
import PageTitle from 'components/PageTitle';
import FilterJobs from 'components/Search/FilterJobs';

import useCustomQuery from 'hooks/useCustomQuery';
import { useMobileDesign } from 'hooks/useMobileDesign';

import generateURL from 'utils/api/generateURL';
import getQueryParams from 'utils/api/getQueryParams';

import { JobRecord, Records, SortDirectionType } from 'types/airtable';

import styles from './BrowseJobsPage.module.scss';

interface UpdateFilters {
  countryFilter: string;
  departmentFilter: string;
  industryFilter?: string;
}

interface FilterBy {
  record: [{ id: string; fields: JobRecord }];
  filterName: string;
  filterValue: string;
}

interface ReturnFilterBy {
  id: string;
  fields: JobRecord;
}

const filterByGeneric = ({
  record,
  filterName,
  filterValue
}: FilterBy): ReturnFilterBy[] =>
  record.filter((item) => {
    if (!filterValue) return record;
    const splitQueryValues = filterValue.split(',');

    return splitQueryValues.includes(
      item?.fields[filterName as keyof JobRecord] as string
    );
  });

const filterByIndustry = ({
  record,
  filterValue
}: FilterBy): ReturnFilterBy[] =>
  record.filter((item) => {
    if (!filterValue) return record;

    const splitQueryValues: string[] = filterValue.split(',');

    return item?.fields.Industry?.some((industryItem) =>
      splitQueryValues.includes(industryItem)
    );
  });

const BrowseJobsPage = (): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [order, setOrder] = useState<SortDirectionType>('desc');
  const [jobsData, setJobsData] = useState<Records[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const { search: query, pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useMobileDesign(500);

  const { country, department, industry } = getQueryParams(query, [
    'country',
    'department',
    'industry',
    'find'
  ]);

  const airtableParams = {
    tableName: 'Jobs',
    viewName: 'Join Posted Jobs',
    pageSize: 100,
    sortField: 'FormatDate',
    sortDirection: order
  };

  const queryKey = `All jobs, ${order}`;

  const { data, isLoading } = useCustomQuery(queryKey, () =>
    AirtableService.getRecords(airtableParams)
  );

  useEffect(() => {
    if (data && data.records) {
      setJobsData(data.records);
      setDataLoaded(true);
    }
  }, [data]);

  const updateFilters = ({
    countryFilter,
    departmentFilter,
    industryFilter
  }: UpdateFilters): void => {
    navigate(
      generateURL(pathname, {
        country: countryFilter,
        department: departmentFilter,
        industry: industryFilter
      }),
      { replace: true }
    );
  };

  const renderContent = (): ReactElement => {
    if (isLoading || !dataLoaded) return <LoadingIndicator />;

    const filteredRecords = filterByGeneric({
      record: filterByIndustry({
        record: jobsData as unknown as [{ id: string; fields: JobRecord }],
        filterName: 'Industry',
        filterValue: industry
      } as FilterBy),
      filterName: 'Location (Country)',
      filterValue: country
    } as FilterBy);

    if (!filteredRecords.length)
      return <EmptyList setIsModalVisible={setIsModalVisible} />;

    return (
      <section className={styles.JobsList} id="jobs-list">
        <div className={`${styles.JobsListContainer} container`}>
          <Row gutter={[16, 16]}>
            <JobsList jobList={filteredRecords || jobsData} />
          </Row>
        </div>
      </section>
    );
  };

  return (
    <>
      <PageTitle>
        <h2 className={`title-custom ${styles.JobsTitle}`}>
          <FormattedMessage id="jobs_list.browse_text" />
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            alignItems: isMobile ? 'center' : 'baseline',
            justifyContent: isMobile ? 'center' : 'space-between'
          }}
        >
          <FilterJobs
            withDataQa
            updateFilters={updateFilters}
            industry={industry}
            country={country}
            department={department}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            jobsData={jobsData}
          />
          <div
            className={styles.FilterJobsButtons}
            style={{ width: isMobile ? '100%' : 'auto' }}
          >
            <Button
              type="default"
              className="btn filter-btn"
              style={{
                marginTop: isMobile ? 20 : 0
              }}
              onClick={() => setOrder(order === 'desc' ? 'asc' : 'desc')}
            >
              {order === 'desc' ? (
                <SortDescendingOutlined />
              ) : (
                <SortAscendingOutlined />
              )}
              <FormattedMessage id="sorting.text" />
            </Button>
          </div>
        </div>
      </PageTitle>
      {renderContent()}
    </>
  );
};

export default BrowseJobsPage;
