import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useIntl } from 'react-intl';

import { FormInstance, Input, Select } from 'antd';
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumberFromString
} from 'libphonenumber-js';

import CountryPreview from 'components/CountryPreview';

import useCountryList from 'hooks/useCountryList';
import useInitialPhoneNumber from 'hooks/useInitialPhoneNumber';
import { useMobileDesign } from 'hooks/useMobileDesign';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { ReactComponent as ArrowDown } from 'assets/icons/selectArrow.svg';

import styles from './index.module.scss';

const { Option } = Select;

interface PhoneSelectProps {
  form: FormInstance;
  name: string;
}

const PhoneSelect: React.FC<PhoneSelectProps> = ({ form, name }) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = useMobileDesign();
  const countryList = useCountryList();
  const {
    selectedCountry,
    phoneCode,
    phoneNumber,
    isValid,
    setSelectedCountry,
    setPhoneCode,
    setPhoneNumber,
    setIsValid
  } = useInitialPhoneNumber(form, name, countryList);
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (containerRef.current) {
      setParentWidth(containerRef.current.offsetWidth);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (phoneNumber) {
      form.setFieldsValue({ [name]: `+${phoneCode}${phoneNumber}` });
      form.setFields([
        { name, errors: isValid ? [] : ['Invalid phone number'] }
      ]);
    }
  }, [phoneCode, phoneNumber, form, name, isValid]);

  const handleCountryChange = useCallback(
    (value: string) => {
      const country = countryList.find((c) => c.value === value);
      if (country) {
        setSelectedCountry(country);
        setPhoneCode(country.callingCode);
      }
    },
    [countryList]
  );

  const handlePhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value.replace(/\s/g, '');
      const withoutCountryCode = input.replace(/^\+\d+/, '');
      const newPhoneNumber = withoutCountryCode;
      setPhoneNumber(newPhoneNumber);
      const parsedPhoneNumber = parsePhoneNumberFromString(
        `+${selectedCountry?.callingCode ?? ''}${newPhoneNumber}`,
        selectedCountry?.code as CountryCode
      );
      setIsValid(
        parsedPhoneNumber ? isValidPhoneNumber(parsedPhoneNumber.number) : false
      );
    },
    [selectedCountry]
  );

  const columnDataChildren = useMemo(
    () =>
      countryList.map((country) => (
        <Option
          key={country.code}
          value={country.value}
          label={<CountryPreview countryCode={country.code} iconOnly />}
        >
          <CountryPreview
            countryCode={country.code}
            countryName={country.label}
          />{' '}
          (+{country.callingCode})
        </Option>
      )),
    [countryList]
  );

  const onConfirm = useCallback(
    (value: any[]) => {
      const selectedValue = value[0] as string;
      const selectedOption = countryList.find(
        (country) => country.value === selectedValue
      );
      if (selectedOption) {
        setSelectedCountry(selectedOption);
        setPhoneCode(selectedOption.callingCode);
      }
      setVisible(false);
    },
    [countryList]
  );

  const onDismiss = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <Input
        addonBefore={
          <Select
            value={selectedCountry?.value || undefined}
            onChange={handleCountryChange}
            optionLabelProp="label"
            popupClassName={styles.dropdownContainer}
            popupMatchSelectWidth={parentWidth}
          >
            {columnDataChildren}
          </Select>
        }
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        prefix={phoneCode ? `+${phoneCode}` : ''}
      />
    </div>
  );
};

export default PhoneSelect;
