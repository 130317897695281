import { createSelector } from 'reselect';

import { RootState } from 'app/store';

import { RequestStatus } from 'types/dictionaries';

export const selectAllDictionaries = (state: RootState) =>
  state.dictionaries.data;

export const selectDictionary = (dict: string) =>
  createSelector(selectAllDictionaries, (data) => data[dict] || []);

export const selectDictionariesStatus = (state: RootState): string =>
  state.dictionaries.status;

export const selectIsDictionariesStatusLoaded = createSelector(
  (state: RootState): RequestStatus => state.dictionaries.status,
  (status) => status === 'succeeded'
);

export const selectDictionariesError = (state: RootState): string | null =>
  state.dictionaries.error;
