import { ReactElement, lazy } from 'react';

import RouteWithLayout from 'routes/components/RouteWithLayout';

import AdamoPage from 'pages/AdamoPage';
import AmbassadorIntakePage from 'pages/AmbassadorIntakePage';
import ApplyInvitePage from 'pages/ApplyInvitePage';
import BrowseJobsPage from 'pages/BrowseJobsPage';
import ChatPage from 'pages/ChatPage';
import IntakePage from 'pages/IntakePage';
import IntakeThankYouPage from 'pages/IntakeThankYouPage';
import JobDetailsPage from 'pages/JobDetailsPage';
import QuestionnairePage from 'pages/QuestionnairePage';
import QuestionnaireThankYouPage from 'pages/QuestionnaireThankYouPage';
import UserProfilePage from 'pages/UserProfilePage';

import {
  ADAMO,
  ADMIN,
  AMBASSADOR,
  AMBASSADOR_DETAILS,
  AMBASSADOR_INTAKE,
  AMBASSADOR_JOBS,
  AMBASSADOR_THANK_YOU,
  APPLY,
  APPLY_INVITE,
  CHAT,
  INTAKE,
  INTAKE_THANK_YOU,
  JOBS,
  JOB_DETAILS,
  MAIN,
  NOT_FOUND,
  QUESTIONNAIRE,
  QUESTIONNAIRE_THANK_YOU,
  USER,
  USER_PROFILE
} from 'constants/pathNames';

const NotFoundPage = lazy(
  () => import(/* chunkName: 'NotFoundPage' */ 'pages/NotFoundPage')
);

const MainPage = lazy(
  () => import(/* chunkName: 'MainPage' */ 'pages/MainPage')
);

type RouteObject = {
  path: string;
  key: string;
  element: ReactElement;
  protectedRoute?: boolean;
  children?: RouteObject[];
};

const createRoute = (
  path: string,
  key: string,
  element: ReactElement,
  protectedRoute: boolean = false,
  children: RouteObject[] = []
): RouteObject => ({
  path,
  key,
  protectedRoute,
  element,
  children
});

const Index = [
  createRoute(APPLY, 'APPLY_ROOT', <RouteWithLayout />, false, []),
  createRoute(MAIN, 'MAIN_ROOT', <RouteWithLayout />, false, [
    createRoute(MAIN, 'MAIN', <MainPage />),
    createRoute(JOB_DETAILS, 'JOB_DETAILS', <JobDetailsPage />),
    createRoute(JOBS, 'JOBS', <BrowseJobsPage />),
    createRoute(APPLY_INVITE, 'APPLY_INVITE', <ApplyInvitePage />),
    createRoute(INTAKE, 'INTAKE', <IntakePage />),
    createRoute(INTAKE_THANK_YOU, 'INTAKE_THANK_YOU', <IntakeThankYouPage />),
    createRoute(ADAMO, 'ADAMO', <AdamoPage />),
    createRoute(AMBASSADOR, 'AMBASSADOR', <MainPage />),
    createRoute(AMBASSADOR_JOBS, 'AMBASSADOR_JOBS', <BrowseJobsPage />),
    createRoute(
      AMBASSADOR_INTAKE,
      'AMBASSADOR_INTAKE',
      <AmbassadorIntakePage />
    ),
    createRoute(
      AMBASSADOR_THANK_YOU,
      'AMBASSADOR_THANK_YOU',
      <IntakeThankYouPage />
    ),
    createRoute(AMBASSADOR_DETAILS, 'AMBASSADOR_DETAILS', <JobDetailsPage />),
    createRoute(QUESTIONNAIRE, 'QUESTIONNAIRE', <QuestionnairePage />),
    createRoute(
      QUESTIONNAIRE_THANK_YOU,
      'QUESTIONNAIRE_THANK_YOU',
      <QuestionnaireThankYouPage />
    )
  ]),
  createRoute(
    ADMIN,
    'ADMIN',
    <RouteWithLayout config={{ hideFooter: true }} />,
    false,
    [createRoute(CHAT, 'CHAT', <ChatPage />)]
  ),
  createRoute(USER, 'USER', <RouteWithLayout />, true, [
    createRoute(USER_PROFILE, 'USER_PROFILE', <UserProfilePage />, true)
  ]),
  createRoute(NOT_FOUND, 'NOT_FOUND', <NotFoundPage />)
];

export default Index;
