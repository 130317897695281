import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from 'utils/api/api';
import formatDictionaryData from 'utils/formatDictionaryData';

import { DICT } from 'constants/endpoints';
import { Dictionaries } from 'constants/enums';

const { Industry, Skillset, Language, LanguageLevel } = Dictionaries;

export const fetchDictionary = createAsyncThunk(
  'dictionaries/fetchDictionary',
  async (dict: string, { rejectWithValue }) => {
    try {
      const response = await get(`${DICT}/${dict}`, {}, true);
      return {
        dict: response.name,
        data: formatDictionaryData(response.data)
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllDictionaries = createAsyncThunk(
  'dictionaries/fetchAllDictionaries',
  async (_, { rejectWithValue }) => {
    const dictionaryNames = [Industry, Skillset, Language, LanguageLevel];
    try {
      const responses = await Promise.all(
        dictionaryNames.map((dict) => get(`${DICT}/${dict}`, {}, true))
      );
      return responses.map((response) => ({
        dict: response.name,
        data: formatDictionaryData(response.data)
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
