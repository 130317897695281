import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import formatAndOrderData from 'utils/formatAndOrderData';

import { LANGUAGE_LEVEL_SPLITTER } from 'constants/common';
import { Dictionaries } from 'constants/enums';
import languagesLevelsOrder from 'constants/languagesLevelsOrder';
import languagesOrder from 'constants/languagesOrder';

import styles from './SelectLanguageLevels.module.scss';
import {
  convertLanguagesWithLevelsArrayToObject,
  convertLanguagesWithLevelsObjectToArray
} from './utils/convertLanguagesWithLevels';

interface SelectLanguageLevelsProps {
  label: string;
  form: FormInstance;
  name: string | string[];
  placeholder?: string;
}

const SelectLanguageLevels: FC<SelectLanguageLevelsProps> = ({
  label,
  form,
  name,
  placeholder
}) => {
  const languagesDict = useSelector(selectDictionary(Dictionaries.Language));
  const languagesLevelsDict = useSelector(
    selectDictionary(Dictionaries.LanguageLevel)
  );
  const formValues = Form.useWatch(name, form);
  const selectedValues = useMemo(() => {
    if (!formValues) {
      return [];
    }

    const convertedValues = convertLanguagesWithLevelsObjectToArray(formValues);

    return convertedValues;
  }, [formValues]);

  const languagesLevelsOptions = useMemo(() => {
    const languagesOptions = formatAndOrderData(languagesDict, languagesOrder);

    const languagesLevelsOptionsSorted = formatAndOrderData(
      languagesLevelsDict,
      languagesLevelsOrder
    );

    const languagesWithLevelsOptions = languagesOptions.map(
      ({ value, label }) => {
        return languagesLevelsOptionsSorted.map(
          ({ value: levelValue, label: levelLabel }) => {
            return {
              value: `${value}${LANGUAGE_LEVEL_SPLITTER}${levelValue}`,
              label: `${label}${LANGUAGE_LEVEL_SPLITTER}${levelLabel}`
            };
          }
        );
      }
    );
    return languagesWithLevelsOptions.flat();
  }, [languagesDict, languagesLevelsDict]);

  const handleChange = (values: string[]) => {
    if (selectedValues.length < values.length) {
      const newValues = values.filter((value) => {
        return !selectedValues.includes(value);
      });

      const oldValuesWithRemovedItemsOfSameLang = selectedValues.filter(
        (oldVal) => {
          const [oldLanguage] = oldVal.split(LANGUAGE_LEVEL_SPLITTER);

          return !newValues.find((newVal) => {
            const [newLanguage] = newVal.split(LANGUAGE_LEVEL_SPLITTER);
            return oldLanguage === newLanguage;
          });
        }
      );
      const newFormValues = convertLanguagesWithLevelsArrayToObject([
        ...newValues,
        ...oldValuesWithRemovedItemsOfSameLang
      ]);

      form.setFieldValue(name, newFormValues);
      return;
    }

    const newFormValues = convertLanguagesWithLevelsArrayToObject(values);

    form.setFieldValue(name, newFormValues);
  };

  return (
    <>
      <Form.Item name={name} style={{ display: 'none' }} />
      <div className={styles.label}>{label}</div>
      <Select
        mode="multiple"
        className="item-select"
        maxTagCount="responsive"
        value={selectedValues}
        onChange={handleChange}
        options={languagesLevelsOptions}
        getPopupContainer={(trigger) => trigger.parentNode}
        placeholder={placeholder}
      />
    </>
  );
};

export default SelectLanguageLevels;
