import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Col, Row } from 'antd';

import { MAIN } from 'constants/pathNames';

import { ReactComponent as Arrow } from 'assets/icons/arrow-left.svg';
import { ReactComponent as Image } from 'assets/images/svg/illustration-404.svg';

import styles from './NotFound.module.scss';

const NotFoundPage = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const onNavToMain = (): void => {
    navigate(MAIN);
  };

  const onNavBack = (): void => {
    // ToDo investigate why location.length is always 1
    // @ts-ignore
    if (location.length < 3) {
      navigate(MAIN);
    } else {
      navigate(-1);
    }
  };

  return (
    <section className={styles.NotFoundWrapper}>
      <div className={`container container-small ${styles.NotFound}`}>
        <h2>
          <FormattedMessage id="not_found.title" />
        </h2>
        <p>
          <FormattedMessage id="not_found.text" />
        </p>
        <div className={styles.NotFoundImage}>
          <Image width="552" />
        </div>
        <Row gutter={[16, 0]} align="middle">
          <Col
            xs={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 2 }}
            className={styles.NotFoundButton}
          >
            <Button
              type="primary"
              className="btn btn-primary"
              block
              onClick={onNavToMain}
            >
              <FormattedMessage id="button.go_to_main_page" />
            </Button>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 1 }}
            className={styles.NotFoundLink}
          >
            <Button
              type="link"
              className="link-btn icon-link"
              onClick={onNavBack}
            >
              <Arrow />
              <FormattedMessage id="button.back_to_previous_page" />
            </Button>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default NotFoundPage;
