import { FormattedOption } from 'types/dictionaries';

const formatAndOrderData = (
  data: FormattedOption[],
  order: string[]
): FormattedOption[] => {
  return [...data].sort((a, b) => {
    const labelA = typeof a.label === 'string' ? a.label : '';
    const labelB = typeof b.label === 'string' ? b.label : '';

    const indexA = order.indexOf(labelA);
    const indexB = order.indexOf(labelB);

    return (
      (indexA !== -1 ? indexA : Infinity) -
        (indexB !== -1 ? indexB : Infinity) || labelA.localeCompare(labelB)
    );
  });
};

export default formatAndOrderData;
