import { LANGUAGE_LEVEL_SPLITTER } from 'constants/common';

const convertLanguagesWithLevelsObjectToArray = (
  languagesWithLevelsObject: Record<string, string>
) => {
  return Object.entries(languagesWithLevelsObject).map(
    ([key, value]) => `${key}${LANGUAGE_LEVEL_SPLITTER}${value}`
  );
};

const convertLanguagesWithLevelsArrayToObject = (
  languagesWithLevelsArray: string[]
) => {
  return languagesWithLevelsArray.reduce(
    (acc, value) => {
      const [language, level] = value.split(LANGUAGE_LEVEL_SPLITTER);
      return { ...acc, [language]: level };
    },
    {} as Record<string, string>
  );
};

export {
  convertLanguagesWithLevelsObjectToArray,
  convertLanguagesWithLevelsArrayToObject
};
