import { ReactElement, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import countriesOptions from 'data/containers/countries';
import { decisionArray, shiftSchedule } from 'data/containers/formData';

import CountryPreview from 'components/CountryPreview';
import SkillsetSelect from 'components/FormCommon/SkillsetSelect';
import MultiSelectCustom from 'components/MultiSelectCustom';
import PhoneSelect from 'components/PhoneSelect';
import SelectLanguageLevels from 'components/SelectLanguageLevels/SelectLanguageLevels';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import useCountryRegionCitySelect from 'hooks/useCountryRegionCitySelect';
import { useGetResource } from 'hooks/useGetResource';

import { convertArraySorted } from 'utils/convertArray';

import { Country } from 'types/commons';

import { LEGAL_BASE_URL, PRIVACY_PATH } from 'constants/application';
import { COUNTRIES } from 'constants/endpoints';
import { Dictionaries } from 'constants/enums';

interface IntakeFormProps {
  form: FormInstance;
  loading: boolean;
  submitForm: () => void;
  initialValues?: Record<string, any>;
}

const IntakeForm = ({
  form,
  loading,
  submitForm,
  initialValues = {}
}: IntakeFormProps): ReactElement => {
  const intl = useIntl();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState<boolean>(false);
  const fieldsValues = Form.useWatch([], form);

  const defaultValidateMessages = {
    required: 'Field is required'
  };

  const { data: countries, isLoading: isCountriesLoading } =
    useGetResource<Country>({ url: COUNTRIES, isOpenApi: true });

  const { countrySelect, stateSelect, citySelect } = useCountryRegionCitySelect(
    { form, countries, isCountriesLoading }
  );

  const industriesDict = useSelector(selectDictionary(Dictionaries.Industry));

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => setIsFormValid(true),
      () => setIsFormValid(false)
    );
  }, [fieldsValues]);

  useEffect(() => {
    if (initialValues.Email) {
      form.setFieldsValue({ Email: initialValues.Email });
    }
  }, [initialValues.Email, form]);

  const onChangePrivacyCheckbox = (e: CheckboxChangeEvent): void => {
    setIsPrivacyChecked(e.target.checked);
  };

  const isFieldDisabled = (fieldName: string): boolean =>
    initialValues && initialValues[fieldName] !== undefined;

  const privacyPolicyLink = (
    <a
      href={`${LEGAL_BASE_URL}en${PRIVACY_PATH}`}
      target="_blank"
      rel="noreferrer"
      aria-label={intl.formatMessage({ id: 'form.intake.policyLink' })}
    >
      <FormattedMessage id="form.intake.policyLink" />
    </a>
  );

  const renderCountryOption = (item: {
    value: string;
    label: string;
    code?: string;
  }): ReactElement => (
    <CountryPreview countryCode={item.code} countryName={item.label} />
  );

  const renderCountryLabel = (option: {
    value: string;
    label: string;
  }): ReactElement => <CountryPreview countryName={option.label} />;

  return (
    <Form
      form={form}
      scrollToFirstError
      name="intakeForm"
      autoComplete="off"
      className="form-container"
      validateMessages={defaultValidateMessages}
      initialValues={{ ...initialValues }}
    >
      <Row gutter={[24, 16]}>
        <Col span={24} md={12}>
          <Form.Item
            name="First Name"
            label={intl.formatMessage({ id: 'form.intake.firstName' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.firstName'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Last Name"
            label={intl.formatMessage({ id: 'form.intake.lastName' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.lastName'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          {countrySelect}
        </Col>
        <Col span={24} md={12}>
          {stateSelect}
        </Col>
        <Col span={24} md={12}>
          {citySelect}
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Mailing Address"
            label={intl.formatMessage({ id: 'form.intake.mailAddress' })}
            tooltip={{
              title: intl.formatMessage({ id: 'form.intake.mailAddress.info' }),
              icon: <InfoCircleOutlined />
            }}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.mailAddress.placeholder'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Citizenship"
            label={intl.formatMessage({ id: 'form.intake.citizenshipNew' })}
            rules={[{ required: true }]}
          >
            <MultiSelectCustom
              name="Citizenship"
              labelId="form.intake.citizenshipNew"
              form={form}
              options={countriesOptions}
              optionRender={renderCountryOption}
              labelRender={renderCountryLabel}
              placeholderId="form.intake.placeholder.citizenshipNew"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Phone Number"
            label={intl.formatMessage({ id: 'form.intake.phone' })}
            rules={[{ required: true }]}
          >
            <PhoneSelect name="Phone Number" form={form} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Email"
            label={intl.formatMessage({ id: 'form.intake.email' })}
            initialValue=""
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.email'
              })}
              disabled={isFieldDisabled('Email')}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <SelectLanguageLevels
            form={form}
            name="Languages"
            label={intl.formatMessage({ id: 'form.intake.languages' })}
            placeholder={intl.formatMessage({
              id: 'form.intake.placeholder.languages'
            })}
          />
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="What are your skills"
            label={intl.formatMessage({ id: 'form.intake.professionNew' })}
            rules={[{ required: true }]}
          >
            <SkillsetSelect
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.professionNew'
              })}
              title=""
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Looking for a job in the following areas"
            label={intl.formatMessage({ id: 'form.intake.occupationNew' })}
            rules={[{ required: true }]}
          >
            <MultiSelectCustom
              name="Looking for a job in the following areas"
              labelId="form.intake.occupationNew"
              form={form}
              options={industriesDict}
              placeholderId="form.intake.placeholder.occupationNew"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Weekend/Overtime"
            label={intl.formatMessage({
              id: 'screening.form.label.areYouOpenToWork'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={convertArraySorted(decisionArray)}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Preferred Schedule"
            label={intl.formatMessage({
              id: 'screening.form.label.selectShift'
            })}
            rules={[{ required: true }]}
          >
            <Checkbox.Group options={convertArraySorted(shiftSchedule)} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Felony Conviction"
            label={intl.formatMessage({
              id: 'screening.form.label.felonyConviction'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={convertArraySorted(decisionArray)}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Background Check Consent"
            label={intl.formatMessage({
              id: 'screening.form.label.backgroundCheck'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={convertArraySorted(decisionArray)}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item className="max-height-group" rules={[{ required: true }]}>
            <Checkbox onChange={onChangePrivacyCheckbox}>
              <FormattedMessage
                id="form.intake.policy"
                values={{ link: privacyPolicyLink }}
              />
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div className="intake-submit-container">
        <Button
          type="primary"
          loading={loading}
          className="btn btn-primary intake-button"
          htmlType="submit"
          onClick={submitForm}
          disabled={!isFormValid || !isPrivacyChecked}
        >
          <FormattedMessage id="form.intake.submit" />
        </Button>
      </div>
    </Form>
  );
};

export default IntakeForm;
