import { useEffect, useState } from 'react';

import { get } from 'utils/api/api';

const useGetResource = function <T>({
  url,
  isDisableLoad = false,
  isOpenApi = false
}: {
  url: string;
  isDisableLoad?: boolean;
  isOpenApi?: boolean;
}): {
  data: T[];
  isLoading: boolean;
} {
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async (url: string) => {
    try {
      setIsLoading(true);

      const response = await get(url, {}, isOpenApi);

      if (isOpenApi) {
        setData(response);
        return;
      }

      setData(response.content);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isDisableLoad) {
      return;
    }
    getData(url);
  }, [url, isDisableLoad]);

  return { data, isLoading };
};

export { useGetResource };
