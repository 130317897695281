const ENGLISH_CHARACTERS_REGEX = /^[A-Za-z'\s]*$/;
export const SALARY_CHARACTERS_REGEX = /^[0-9$€£¥₹\-,.\s]*$/;

const validateAllowedRegExp = (
  value: string,
  regex: RegExp,
  errorMessage: string
): Promise<void> => {
  if (value && !regex.test(value)) {
    return Promise.reject(new Error(errorMessage));
  }
  return Promise.resolve();
};

const validateEnglishCharacters = (_: unknown, value: string): Promise<void> =>
  validateAllowedRegExp(
    value,
    ENGLISH_CHARACTERS_REGEX,
    'Please use the English letters only'
  );

const validateSalaryCharacters = (_: unknown, value: string): Promise<void> =>
  validateAllowedRegExp(
    value,
    SALARY_CHARACTERS_REGEX,
    'Not allowed characters'
  );

export { validateEnglishCharacters, validateSalaryCharacters };
