import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { AutoComplete, Form, Input } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import { FormInstance } from 'antd/lib';
import CommonsService from 'services/commonsService';

import CountryPreview from 'components/CountryPreview';
import SelectCustom from 'components/SelectCustom';

import formatAndOrderData from 'utils/formatAndOrderData';
import { validateEnglishCharacters } from 'utils/validators';

import { City, Country, SelectOption } from 'types/commons';

import countriesData, { COUNTRIES_ORDER } from 'constants/countriesData';

interface UseCountryRegionCitySelectProps {
  form: FormInstance;
  countryFieldName?: string;
  stateFieldName?: string;
  cityFieldName?: string;
  isCountryRequired?: boolean;
  isStateRequired?: boolean;
  isCityRequired?: boolean;
  countries?: Country[];
  isCountriesLoading?: boolean;
}

interface CountryOption {
  label: string;
  value: string;
  code: string;
}

interface State {
  value: string;
  label: string;
  code: string;
}

const MIN_SPELLING_SYMBOLS = 3;

const useCountryRegionCitySelect = ({
  form,
  countryFieldName = 'Currently Residing',
  stateFieldName = 'State/Province of residence',
  cityFieldName = 'City of Residence',
  isCountryRequired = true,
  isStateRequired = false,
  isCityRequired = false,
  countries,
  isCountriesLoading = false
}: UseCountryRegionCitySelectProps): {
  countrySelect: JSX.Element;
  stateSelect: JSX.Element;
  citySelect: JSX.Element;
} => {
  const intl = useIntl();
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<DefaultOptionType[]>([]);
  const [stateCode, setStateCode] = useState('');
  const [isStatesLoading, setIsStatesLoading] = useState<boolean>(false);

  const state = Form.useWatch(stateFieldName, form);
  const country = Form.useWatch(countryFieldName, form);
  const isCountryWithStates = countriesData.some(
    ({ code }) => code === country
  );

  useEffect(() => {
    if (!country) {
      return;
    }

    if (!isCountryWithStates) {
      setStates([]);
      return;
    }

    setCities([]);
    fetchStates(country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, isCountryWithStates]);

  useEffect(() => {
    if (state) {
      setStateCode(getStateCode(states, state));
    }
  }, [states, state]);

  const getStateCode = (states: State[], label: string): string => {
    const state = states.find((item) => item.label === label);
    return state?.code || '';
  };

  const countriesOptions = useMemo(() => {
    if (!countries) {
      return countriesData.map(({ value, label }) => ({
        value,
        label
      }));
    }

    const options = countries.map(({ code, name }) => ({
      value: code,
      label: name,
      code
    }));

    const sortedOptions = formatAndOrderData(options, COUNTRIES_ORDER);

    return sortedOptions as { value: string; label: string }[];
  }, [countries]);

  const fetchStates = useCallback(
    async (countryCode: string) => {
      try {
        setIsStatesLoading(true);
        const response =
          await CommonsService.getStatesByCountryCode(countryCode);
        const newStates = response.map((stateListItem) => ({
          value: stateListItem.value,
          label: stateListItem.value,
          code: stateListItem.key
        }));
        setStates(newStates);
      } catch (error) {
        console.error(error);
      } finally {
        setIsStatesLoading(false);
      }
    },
    [setIsStatesLoading]
  );

  const fetchCities = useCallback(
    async (spelling: string, state: string) => {
      if (spelling.length < MIN_SPELLING_SYMBOLS) {
        setCities([]);
        return;
      }
      try {
        const response = await CommonsService.getCitiesByStateAndSpelling(
          state,
          spelling
        );
        const newCities = response.map((city: City) => ({
          label: city.value,
          value: city.value,
          key: city.key
        }));
        setCities(newCities);
      } catch (error) {
        console.error(error);
      }
    },
    [setCities]
  );

  const handleCitiesSearch = useCallback(
    (spelling: string) => {
      fetchCities(spelling, stateCode);
    },
    [fetchCities, stateCode]
  );

  const renderCountryOption = ({
    data
  }: {
    data: CountryOption;
  }): ReactElement => (
    <CountryPreview countryCode={data?.code} countryName={data?.label} />
  );

  const renderCountryLabel = (option: {
    value: string;
    label: string;
  }): ReactElement => <CountryPreview countryName={option.label} />;

  const countrySelect = (
    <Form.Item
      name={countryFieldName}
      label={intl.formatMessage({ id: 'form.intake.countryOfLivingNew' })}
      className="picker-mobile"
      rules={[{ required: isCountryRequired }]}
    >
      <SelectCustom
        name={countryFieldName}
        form={form}
        options={countriesOptions}
        placeholderId="form.intake.placeholder.countryOfLivingNew"
        labelId="form.intake.countryOfLivingNew"
        optionFilterProp="label"
        optionRender={renderCountryOption}
        labelRender={renderCountryLabel}
        loading={isCountriesLoading}
        showSearch
      />
    </Form.Item>
  );

  const stateSelect = (
    <>
      <Form.Item
        name={stateFieldName}
        label={intl.formatMessage({ id: 'form.intake.stateOfResidence' })}
        className="picker-mobile"
        rules={[{ required: isStateRequired }]}
      >
        <SelectCustom
          name={stateFieldName}
          form={form}
          options={states}
          placeholderId="form.intake.placeholder.stateOfResidence"
          labelId="form.intake.stateOfResidence"
          loading={isStatesLoading}
          disabled={!isCountryWithStates}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </>
  );

  const citySelect = (
    <Form.Item
      name={cityFieldName}
      label={intl.formatMessage({ id: 'form.intake.cityOfResidence' })}
      rules={[
        { required: isCityRequired, validator: validateEnglishCharacters }
      ]}
    >
      {isCountryWithStates ? (
        <AutoComplete
          options={cities}
          filterOption={false}
          onSearch={handleCitiesSearch}
          placeholder={intl.formatMessage({
            id: 'form.intake.placeholder.cityOfResidence'
          })}
          allowClear
          disabled={!stateCode}
        />
      ) : (
        <Input
          placeholder={intl.formatMessage({
            id: 'form.intake.placeholder.cityOfResidence'
          })}
        />
      )}
    </Form.Item>
  );

  return {
    countrySelect,
    stateSelect,
    citySelect
  };
};

export default useCountryRegionCitySelect;
