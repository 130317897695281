import { Gutter } from 'antd/es/grid/row';

export const DEFAULT_NOTIFICATION_SHOW_DURATION_SEC = 3;
export const TABLE_PAGING_VALUES = [10, 20, 50];
export const FIRST_PAGE_INDEX = 0;
export const PAGE_SIZE = 10;
export const MAX_PAGE_SIZE = 100;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
export const MINIMAL_PASSWORD_LENGTH = 8;
export const SAVE_FILTERS_DEBOUNCE_TIMEOUT_MS = 5000;
export const TYPE_INPUT_DEBOUNCE_TIMEOUT_MS = 500;
export const AUTO_SAVE_INPUT_DEBOUNCE_TIMEOUT_MS = 1000;
export const FALLBACK_VALUE = '-';
export const SORT_BY_CREATED_DATE = 'createdDate';
export const LANGUAGE_LEVEL_SPLITTER = ' - ';
export const SECTION_ROW_GUTTER = [16, 16] as [Gutter, Gutter];
export const MIN_SEARCH_LENGTH = 3;
export const SWITCH_OPTIONS = {
  YES: 'yes',
  NO: 'no'
};
export const MAX_ITEMS_TO_GET = 10000;
