const authUserName = 'admin';
const authUserPassword = 'birj!vbj098_';

const authorizationHeader = {
  Authorization: `Basic ${window.btoa(`${authUserName}:${authUserPassword}`)}`
};

const getHeaders = (): Record<string, string> => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  return {
    ...headers,
    ...authorizationHeader
  };
};

export { getHeaders, authorizationHeader };
