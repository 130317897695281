const LOOKUP_TYPES = {
  JOB_COUNTRY: 'JOB_COUNTRY',
  JOB_INDUSTRY: 'JOB_INDUSTRY',
  COUNTRY: 'COUNTRY',
  SKILL: 'SKILL',
  INDUSTRY: 'INDUSTRY',
  LANGUAGES: 'LANGUAGES'
};

const ATTACHMENTS_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO'
};

// Make sure that if the endpoint is not combined should be ended without slash "/"
const ENDPOINT = {
  CLIENTS: '/api/v1/clients',
  OPEN_JOBS: '/api/v1/open/jobs',
  LOOKUPS: '/api/v1/lookups/',
  GEO_IP: '/api/v1/geoip/',
  DETAILS_JOB: '/api/v1/open/bounty_fetch/',
  USER_REQUEST_GENERIC: '/api/v1/requests/',
  INDUSTRIES: '/api/v1/open/industries',
  REFERRAL: '/api/v1/referrals',
  AUTH_EMAIL_VERIFY: '/auth/email/verify',
  AUTH_EMAIl_SEND: '/auth/email/send'
};

const HOST = import.meta.env.VITE_API_HOST;
const OPEN_API = import.meta.env.VITE_OPEN_API_HOST;

const AIRTABLE_HOST = `${HOST}/proxy/v0/${import.meta.env.VITE_AIRTABLE_BASE}`;

export {
  LOOKUP_TYPES,
  ATTACHMENTS_TYPE,
  ENDPOINT,
  HOST,
  AIRTABLE_HOST,
  OPEN_API
};
