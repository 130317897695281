import { ReactElement, memo } from 'react';

import JobCard from 'components/Job/JobCard';

import { JobRecord } from 'types/airtable';

export interface JobListProps {
  id: string;
  fields: JobRecord;
  createdTime: string;
}

const JobsList = ({
  jobList
}: {
  jobList: JobListProps[];
}): ReactElement[] | ReactElement =>
  jobList.map((job: JobListProps) => <JobCard job={job} key={job.id} />);

export default memo(JobsList);
