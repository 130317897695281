// Only 2 default countries, do not change unless the business requirements are changed

const countriesData = [
  { label: 'Canada', value: 'CA', code: 'CA' },
  { label: 'USA', value: 'US', code: 'US' }
];

const COUNTRIES_ORDER = ['USA', 'Canada'];

export default countriesData;
export { COUNTRIES_ORDER };
