import { get } from 'utils/api/api';

import { City, Country, StateListItem } from 'types/commons';

import { COUNTRIES, STATES } from 'constants/endpoints';

class CommonsService {
  async getCountries(): Promise<Country> {
    const response = await get(COUNTRIES, {}, true);
    return response;
  }

  async getStatesByCountryCode(countryCode: string): Promise<StateListItem[]> {
    const response = await get(`${STATES}/${countryCode}`, {}, true);
    return response;
  }

  async getCitiesByStateAndSpelling(
    state: string,
    spelling: string
  ): Promise<City[]> {
    const response = await get(`${STATES}/${state}/${spelling}`, {}, true);
    return response;
  }
}

export default new CommonsService();
